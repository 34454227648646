import React from 'react';
import { node, string } from 'prop-types';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

const EFFECT_TYPE = {
  bounce: 'bounce',
  fade: 'fade',
  zoom: 'zoom'
};

/**
 * component Adapter for React-reveal
 * See full documentation at https://www.react-reveal.com/examples/common/fade/
 * @param {React.ReactNode} children
 * @returns {React.ReactNode} wrapper with Fade Effect
 */
const WithAnimation = ({ type, children, ...rest }) => {
  if (type === EFFECT_TYPE.bounce) {
    return (
      <Bounce bottom {...rest}>
        {children}
      </Bounce>
    );
  }
  else if (type === EFFECT_TYPE.zoom) {
    return (
      <Zoom cascade {...rest}>
        {children}
      </Zoom>
    );
  }

  return (
    <Fade bottom {...rest}>
      {children}
    </Fade>
  );
};

WithAnimation.propTypes = {
  type: string,
  children: node.isRequired,
};

WithAnimation.defaultProps = {
  type: '',
};

export default WithAnimation;
