import { ENABLE_ANNOUNCEMENT_MODE, ENABLE_QR_INVITATION } from '../constants/feature-flags';
import useQueryString from '@/hooks/useQueryString';
import { useGuest } from '@/context/guest';
import useGroupLink from './useGroupLink';
import { getItem } from '@/helpers/localstorage';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useInvitation() {
  const { getQueryValue } = useQueryString();

  if (!ENABLE_ANNOUNCEMENT_MODE) return true;

  const type = getQueryValue('type', '');
  const code = getQueryValue('code');
  const isGroup = useGroupLink();
  const savedCode = (isGroup && getItem('guestCode')) || '';

  const { isRegistered, isLoading } = useGuest();

  if (code || savedCode) {
    if (!isLoading && !isRegistered) {
      return false;
    }
  } else if (!isGroup) return false;

  // Handle QR Version
  if (ENABLE_QR_INVITATION && !isLoading && !isRegistered) {
    return false;
  }

  return type === 'invitation' || type === 'family';
}

export default useInvitation;
