import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,','Dear Mr/Mrs/Ms,'),
  guest: get('Keluarga & Teman-teman','Family & Friends'),
  welcomingInvitationMuslim: get(
    `Dengan memohon rahmat dan ridho Allah SWT, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    `Together with joyful hearts and the grace of Allah SWT, we cordially request the honor of your presence at the wedding celebration of:`
  ),
  welcomingInvitationGeneral: get(
    `Dengan memohon anugerah dan berkat Tuhan, kami memohon kehadiran Bapak/Ibu/Saudara/i pada acara pernikahan kami:`,
    'Together with joyful hearts and the grace of God, we cordially request the honor of your presence at the wedding celebration of:'
  ),
  welcomingAnnouncementMuslim: get(
    `Dengan memohon rahmat dan ridho Allah SWT, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:`,
    `Together with the blessing of Allah SWT, we joyfully announce the upcoming marriage of:`
  ),
  welcomingAnnouncementGeneral: get(
    'Dengan memohon anugerah dan berkat Tuhan, kami bermaksud mengumumkan kepada Bapak/Ibu/Saudara/i acara pernikahan kami:',
    'Together with joyful hearts and the grace of God, we joyfully announce the upcoming marriage of:'
  ),
  ayatName: get('Matius 19:6', 'Matthew 19:6'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.`,
    `So they are no longer two, but one. Therefore what God has joined together, let no one separate.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And among His Signs is this, that He created for you mates from among yourselves, that He may dwell in tranquillity with them, and He has put love and mercy between your (hearts): verily in that are Signs for those who reflect.`,
  ),
};